import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable array-callback-return */
/* eslint-disable no-prototype-builtins */
import { useCallback } from 'react';
import { CnImageViewer } from '@cainiaofe/cn-ui';
import styled from 'styled-components';
export default function DWTableCellImg(props) {
    const { value } = props;
    // if (!value) {
    //   return '-';
    // }
    // if (!Array.isArray(value)) {
    //   return (
    //     <span style={{ color: '#f00' }}>
    //       数据格式请使用数组，参考示例：[url1,url2,url3]
    //     </span>
    //   );
    // }
    // console.log('DWDetailItemProps ======>', props);
    // console.log('DWDetailItem value ======>', value);
    // const value = [
    //   'eorror',
    //   'https://intranetproxy.alipay.com/skylark/lark/0/2020/png/291582/1587705359290-avatar/3c2572f0-207e-446d-96cd-946bd32ee8f8.png?x-oss-process=image%2Fresize%2Cm_fill%2Cw_320%2Ch_320%2Fformat%2Cpng',
    //   'https://img.alicdn.com/imgextra/i2/O1CN01LiaDQM1R3EbJbMf0k_!!6000000002055-0-tps-284-177.jpg',
    //   'https://img.alicdn.com/imgextra/i3/O1CN01ToljP81agvSvIU2lO_!!6000000003360-0-tps-225-225.jpg',
    //   'https://img.alicdn.com/imgextra/i3/O1CN01e0ADUL1JwdaMDib0Q_!!6000000001093-0-tps-1440-810.jpg',
    //   'https://img.alicdn.com/imgextra/i3/O1CN01YXRLGm1FjZpFDZZtb_!!6000000000523-2-tps-1080-2340.png',
    // ];
    // return <CnImageViewer src={value} width={40} height={40} style={{ flexWrap: 'wrap' }} />;
    const handleClick = useCallback((item) => {
        CnImageViewer.open({
            src: value,
            value: item,
        });
    }, [value]);
    const renderImgs = useCallback(() => {
        if (!value) {
            return '-';
        }
        if (!Array.isArray(value)) {
            return _jsx("span", { style: { color: '#f00' }, children: "\u6570\u636E\u683C\u5F0F\u8BF7\u4F7F\u7528\u6570\u7EC4\uFF0C\u53C2\u8003\u793A\u4F8B\uFF1A[url1,url2,url3]" });
        }
        return value?.map((item, index) => {
            return (_jsx(ImgBox, { width: 40, height: 40, src: item, alt: "", onClick: () => {
                    handleClick(item);
                } }, index));
        });
    }, [handleClick, value]);
    return _jsx(ImageViewWrap, { theme: { colum: value?.length || 1 }, children: renderImgs() });
}
const ImageViewWrap = styled.div `
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, 40px);
  justify-content: space-between;
`;
const ImgBox = styled.img `
  cursor: pointer;
`;
DWTableCellImg.displayName = 'DWTableCellImg';
export { DWTableCellImg };
