import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react/jsx-no-bind */
import { useState } from 'react';
import { CnMessage, CnLoading, Upload, Button } from '@cainiaofe/cn-ui';
import { request } from 'cn-request';
import './index.scss';
function View(props) {
    const { action, accept = '', value = [], onChange = (val) => {
        console.log('val', val);
    }, params = {}, btnType = 'primary', limit, } = props;
    const val2 = JSON.parse(JSON.stringify(value || []))?.filter((item) => !!item);
    if (val2?.length > 0) {
        val2?.forEach((item) => {
            item.uid = item.key;
        });
    }
    // console.log('val2========>', val2);
    // const [fileList, setFileList] = useState(val2);
    const [visible, setVisible] = useState(false);
    // console.log('fileList============>', fileList);
    function beforeUpload(info) {
        // console.log('beforeUpload : ', info);
        setVisible(true);
    }
    function onFileChange(val) {
        // console.log('onFileChange=========>', val);
        const curFileList = [];
        val.map((item) => {
            if (item.originFileObj) {
                const { response } = item;
                item.name = response.name;
                curFileList.push(response);
            }
            else {
                curFileList.push(item);
            }
            return item;
        });
        // setFileList(val);
        onChange(curFileList);
    }
    function onSuccess(info) {
        // console.log('onSuccess : ', info);
        // const { response } = info;
        // fileList.push(response);
        // setFileList([...fileList]);
        // onChange(fileList);
        setVisible(false);
    }
    function formatter(res, file) {
        const { success, data } = res;
        if (success) {
            return {
                success: true,
                name: data.name,
                uid: data.key,
                key: data.key,
                url: data.url,
            };
            // fileList.push({
            //   name: data.name,
            //   url: data.url,
            //   downloadURL: data.url,
            //   key: data.key,
            // });
            // setFileList([...fileList]);
        }
        // else {
        //   CnMessage.error('上传失败');
        // }
        return false;
    }
    function customRequest(option) {
        // console.log('option=============>', option);
        // console.log('request=============>', request);
        const formData = new FormData();
        if (option.data) {
            Object.keys(option.data).forEach((key) => {
                formData.append(key, option.data[key]);
            });
        }
        if (option.file instanceof Blob) {
            formData.append(option.filename, option.file, option.file.name);
        }
        else {
            formData.append(option.filename, option.file);
        }
        request?.({
            ...option,
            url: option.action,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' },
            method: 'post',
        })
            .then((response) => {
            const { data, success, errorMsg } = response;
            if (success && data) {
                option.onSuccess(response);
            }
            else {
                setVisible(false);
                option.onError(errorMsg);
            }
        })
            .catch((error) => {
            setVisible(false);
            option.onError(error);
        });
    }
    return (_jsx(Upload, { action: action, beforeUpload: beforeUpload, formatter: formatter, accept: accept, data: {
            ...params,
        }, onChange: onFileChange, onSuccess: onSuccess, onError: (file, val) => {
            CnMessage.error('上传失败');
            setVisible(false);
        }, request: customRequest, listType: "text", 
        // defaultValue={fileList}
        value: val2, limit: limit, children: _jsx(CnLoading, { visible: visible, size: "small", children: _jsx(Button, { type: btnType, style: { margin: '0 0 10px' }, children: "\u4E0A\u4F20\u6587\u4EF6" }) }) }));
}
const DWFileUpload = View;
DWFileUpload.displayName = 'DWFileUpload';
export { DWFileUpload };
