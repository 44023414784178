import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react/jsx-no-bind */
import { useState } from 'react';
import { CnMessage, CnDialog, CnButton, Upload, Icon } from '@cainiaofe/cn-ui';
import './index.scss';
const _window = window;
if (!_window.__cn_request_instance.request) {
    _window.__cn_request_instance.reInit({
        cnOptions: {},
        axiosConfig: {},
    });
}
function View(props) {
    const { action = '', accept = '', children = '', templateUrl = '', params = {} } = props;
    const [visible, setVisible] = useState(false);
    const [errorVisible, setErrorVisible] = useState(false);
    const [errorUrl, setErrorUrl] = useState('');
    const [canUpload, setCanUpload] = useState(false);
    let uploadInstance = null;
    function beforeUpload(info) {
        setVisible(true);
    }
    function onFileChange(val) {
        if (val.length > 0) {
            setCanUpload(true);
        }
        else {
            setErrorVisible(false);
            setErrorUrl('');
            setCanUpload(false);
        }
    }
    function onSuccess(info) { }
    function formatter(res, file) {
        // console.log('res===========>', res);
        const resData = res.data || res;
        const { success, data, errorMsg } = resData;
        if (success) {
            if (data) {
                setErrorUrl(data);
                setErrorVisible(true);
            }
            else {
                setVisible(false);
                _window.location.reload();
            }
        }
        else {
            CnMessage.error(errorMsg);
        }
        return false;
    }
    function customRequest(option) {
        const formData = new FormData();
        if (option.data) {
            Object.keys(option.data).forEach((key) => {
                formData.append(key, option.data[key]);
            });
        }
        if (option.file instanceof Blob) {
            formData.append(option.filename, option.file, option.file.name);
        }
        else {
            formData.append(option.filename, option.file);
        }
        _window.__cn_request_instance.request
            ?.post(option.action, formData, option)
            .then((response) => {
            option.onSuccess(response);
        })
            .catch((error) => {
            option.onError(error);
        });
    }
    const saveUploaderRef = (ref) => {
        if (!ref)
            return;
        uploadInstance = ref.getInstance();
    };
    const onOk = async () => {
        if (canUpload) {
            uploadInstance?.startUpload();
        }
        else {
            CnMessage.error('请先选择文件');
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(CnButton, { onClick: () => {
                    setVisible(true);
                }, size: "medium", type: "normal", children: children || '文件导入' }), _jsxs(CnDialog, { size: "medium", title: `请导入`, visible: visible, onOk: onOk, onCancel: () => {
                    setVisible(false);
                    setErrorVisible(false);
                    setErrorUrl('');
                }, onClose: () => {
                    setVisible(false);
                    setErrorVisible(false);
                    setErrorUrl('');
                }, children: [_jsxs(CnMessage, { type: 'notice', shape: 'inline', visible: true, children: ["\u6570\u636E\u6A21\u7248\uFF1A", _jsx("a", { href: `${templateUrl}`, target: "blank", children: "\u70B9\u51FB\u4E0B\u8F7D" })] }), _jsx(Upload.Dragger, { action: action, ref: saveUploaderRef, beforeUpload: beforeUpload, formatter: formatter, accept: accept, limit: 1, data: {
                            ...params,
                        }, autoUpload: false, onChange: onFileChange, onSuccess: onSuccess, onError: (file, val) => {
                            // setVisible(false);
                        }, request: customRequest, listType: "text", children: _jsxs(CnButton, { style: { margin: 8 }, children: [_jsx(Icon, { type: "upload" }), "\u9009\u62E9\u6587\u4EF6"] }) }), _jsxs(CnMessage, { type: 'error', shape: 'inline', visible: errorVisible, closeable: false, children: ["\u6821\u9A8C\u5931\u8D25\u7684\u6570\u636E\u884C\uFF1A", _jsx("a", { href: `${errorUrl}`, target: "blank", children: "\u70B9\u51FB\u4E0B\u8F7D" })] })] })] }));
}
const DWFileImport = View;
DWFileImport.displayName = 'DWFileImport';
export { DWFileImport };
